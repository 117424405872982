import React, { useState, useEffect, useContext } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";

import ItemView from "./components/ItemView";
import ItemViewTypeSwitch from "./components/ItemViewTypeSwitch";
import Feed from "./Feed";
import ItemViewPublicPrivateSwitch from "./components/ItemViewPublicPrivateSwitch";
import Onboarding from "./bamboo-components/Onboarding";
import { GLOBAL_ITEMS, ITEM } from "./queries";
import * as utils from "./utils"; // Adjust the path based on your file structure
import GlobalContext from "./GlobalContext";

// the internal state, or react state should be the source of truth about what we're viewing. This includes:
//    -itemId
//    -itemName
//    -viewType
// we should then just try to keep the url params in sync with that state

export default function ItemViewPage({ isLoggedIn }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { userSettings, setUserSettings } = useContext(GlobalContext);
  const [isPublicView, setIsPublicView] = useState(false);
  const [pageNeedsReload, setPageNeedsReload] = useState(false);

  const [viewType, setViewType] = useState("top");
  const [item, setItem] = useState({});
  const [itemDummy, setItemDummy] = useState({}); // whatever
  const [itemId, setItemId] = useState();
  const [itemName, setItemName] = useState();
  const [pageNum, setPageNum] = useState();
  const [numLevelsToFlatten, setNumLevelsToFlatten] = useState(0);
  const [wantGlobalItems, setWantGlobalItems] = useState(false);

  // TODO: determine whether we actually need this variable
  const [
    needToCheckIfWeShouldSwitchToPublicView,
    setNeedToCheckIfWeShouldSwitchToPublicView,
  ] = useState(true);

  const navigate = useNavigate();

  const [
    getItem,
    { loading: itemDataLoading, error: itemDataError, data: itemData },
  ] = useLazyQuery(ITEM, { fetchPolicy: "no-cache" });
  const [
    getGlobalItems,
    { loading: giDataLoading, error: giDataError, data: giData },
  ] = useLazyQuery(GLOBAL_ITEMS, { fetchPolicy: "no-cache" });

  // Imo, child components shouldn't be able to call this directly. They should be allowed to set the itemId for this component and setPageNeedsReload(true), and then this component should handle the rest.
  function switchPageToNewItem() {
    const baseVariables = {
      id: itemId,
      name: itemName,
      isPublicView: isPublicView,
      pageNum: pageNum,
    };

    if (wantGlobalItems) {
      getGlobalItems({
        variables: baseVariables,
      })
        .then((response) => {
          const newSearchParams = new URLSearchParams(searchParams.toString());
          if (response?.data?.item) {
            newSearchParams.set("item_id", response.data.item.id);
            navigate(
              {
                search: newSearchParams.toString(),
              },
              { replace: true }
            );
          }
          setPageNeedsReload(false);
        })
        .catch((error) => {
          console.error("Error fetching item:", error);
        });
    } else {
      getItem({
        variables: baseVariables,
      })
        .then((response) => {
          const newSearchParams = new URLSearchParams(searchParams.toString());
          if (response?.data?.item) {
            newSearchParams.set("item_id", response.data.item.id);
            navigate(
              {
                search: newSearchParams.toString(),
              },
              { replace: true }
            );
          }
          setPageNeedsReload(false);
        })
        .catch((error) => {
          console.error("Error fetching item:", error);
        });
    }
  }

  useEffect(() => {
    if (pageNeedsReload) {
      switchPageToNewItem();
    }
  }, [pageNeedsReload]);

  useEffect(() => {
    var pageNum = searchParams.get("page");
    setPageNum(pageNum);

    var iid = searchParams.get("item_id");
    setItemId(iid);

    var iName = searchParams.get("name");
    setItemName(iName);

    var p = searchParams.get("public");

    if (p === "true") {
      setIsPublicView(true);
    } else {
      setIsPublicView(false);
    }

    var vt = searchParams.get("view_type");

    if (!iid && !iName) {
      setWantGlobalItems(true);
      vt = "chronological";
    }

    if (["chronological", "top", "shuffle"].includes(vt)) {
      setViewType(vt);
    } else if (vt !== null) {
      alert("Invalid view type: " + vt);
    }

    setPageNeedsReload(true);
  }, []);

  useEffect(() => {
    setNeedToCheckIfWeShouldSwitchToPublicView(true);
  }, [itemId, itemName]);

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  function sortKidsAndSetItem(i) {
    if (!i || isEmptyObject(i)) {
      return;
    }

    const sortedChildItems = utils.sortChildItems(viewType, i, isPublicView);

    setItem({
      ...i,
      childItems: sortedChildItems,
    });
  }

  useEffect(() => {
    if (viewType === "study") {
      console.log("heyheyhey study view here");
    } else {
      sortKidsAndSetItem(itemDummy);
    }
  }, [itemDummy, viewType, isPublicView]);

  useEffect(() => {
    if (
      typeof itemData?.item.name != "undefined" &&
      itemData?.item.name != null
    ) {
      document.title = itemData?.item.name;
    }
    if (itemData && itemData.item) {
      setItemDummy(itemData?.item);
    }
  }, [itemData]);

  useEffect(() => {
    if (!giData) {
      return;
    }
    var pseudoItem = {
      // pretty sure the userSettings.user is always null here
      id: null,
      user: userSettings.user,
      childItems: giData?.globalItems || [],
    };
    setItemDummy(pseudoItem);
  }, [giData]);

  useEffect(() => {
    if (!(itemDummy && itemDummy.item)) {
      return;
    }

    setItemId(itemDummy.item.id);

    // TODO: determine whether we actually need this variable and code block
    if (needToCheckIfWeShouldSwitchToPublicView) {
      var iouid = itemDummy.item.user ? itemDummy.item.user.id : null;
      // previously, i was thinking that if the user doesn't own the item OR they have an empty item and the public version is poppin, then we should switch them to the public view. but I know think that's wrong, and we should let just keep them in the private view if they ask for their own item.
      // if (iouid != userSettings.userId || (itemData.item.childItems.length == 0 &&      itemData.item.numPublicChildren > 0) ) {
      if (iouid != userSettings.userId) {
        setIsPublicView(true);
      }
      setNeedToCheckIfWeShouldSwitchToPublicView(false);
    }
  }, [itemDummy]);

  if (itemDataLoading)
    return <div style={{ marginLeft: "20px" }}>loading stuff...</div>;

  if (!isLoggedIn) return <Onboarding />;

  return (
    <div className="m-2">
      <div
        style={{
          display: "flex", // This will align the child components in a row
          alignItems: "center", // This will vertically align the child components in the center
          marginLeft: "20px",
          marginBottom: "30px",
          marginTop: "16px",
          gap: "10px", // This will add some space between the child components
        }}
      >
        <ItemViewTypeSwitch
          item={item}
          currentViewType={viewType}
          onClick={setViewType}
        />
        <ItemViewPublicPrivateSwitch
          isPublic={isPublicView}
          setIsPublicView={(newIsPublicViewVal) => {
            setIsPublicView(newIsPublicViewVal);
            setPageNeedsReload(true);
          }}
        />
      </div>
      {viewType == "study" ? (
        <Feed
          isLoggedIn={isLoggedIn}
          purpose={"study"}
          navigatePageToItem={(newItemId) => {
            setWantGlobalItems(false);
            setItemId(newItemId);
            setPageNeedsReload(true);
          }}
        />
      ) : (
        <ItemView
          item={item}
          loading={itemDataLoading || giDataLoading}
          error={itemDataError}
          navigatePageToItem={(newItemId) => {
            setWantGlobalItems(false);
            setItemId(newItemId);
            setPageNeedsReload(true);
          }}
          refetchItem={() => {
            setPageNeedsReload(true);
          }}
          publicView={isPublicView}
          defaultToZenMode={false}
          purpose={"graph"}
          dragAndDrop={viewType === "arbitrary"}
        />
      )}
      {/* )} */}
    </div>
  );
}
