import React, { useRef, useState, useEffect } from "react";
import { CAPTURE } from "../mutations";
import { useMutation, gql } from "@apollo/client";

function ItemCreateModal({ extantItem, refetch, onItemCreate }) {
  const [waitingForSecondClick, setWaitingForSecondClick] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [flash, setFlash] = useState(false);
  const [actionToRapidFlash, setActionToRapidFlash] = useState();

  const inputRef = useRef();
  const timeoutRef = useRef();

  const [captureMutation, { data, loading, error }] = useMutation(CAPTURE);

  function handleKeyDown(e) {
    console.log("keydown boi!");
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action of the enter key in a textarea (which is to insert a newline)
      handleSubmit("add_child_note");
    }
  }

  function handleSubmit(action) {
    clearTimeout(timeoutRef.current);

    let inputText = inputRef.current.value;

    if (inputText == "" && !action.startsWith("make_")) {
      // actions that start with "make" all have to do with making kids and the prompt can be empty for those.
      alert("forreal? put some shit in that box. playin with my damn app.");
      // setIsModalVisible(false);
      return;
    }

    setActionToRapidFlash(action);

    captureMutation({
      variables: {
        inputText: inputText,
        action: action,
        extantItemId: extantItem.id ? extantItem.id : 0,
      },
    })
      .then((response) => {
        if (response.data.capture.item) {
          onItemCreate(response.data.capture.item);
        } else {
          alert("action successful! refresh in a second to see results..");
        }

        // for now let's rapid flash until the request completes
        // timeoutRef.current = setTimeout(() => {
        //   console.log("2 seconds have elapsed!");
        //   // setActionToRapidFlash(null);
        //   setIsModalVisible(false);
        // }, 2000);
      })
      .catch((error) => {
        alert("Error creating item:", error);
      })
      .finally(() => {
        // Code to be executed regardless of success or failure
        setWaitingForSecondClick(false);
        setActionToRapidFlash(null);
        // setIsModalVisible(false);
        // Set focus back to the textarea after the mutation completes
        if (inputRef.current) {
          inputRef.current.focus(); // Focus the textarea element
        }
      });
    inputRef.current.value = "";

    setWaitingForSecondClick(false);
  }

  const handleClick = () => {
    if (!waitingForSecondClick) {
      // setWaitingForSecondClick(true);
      setIsModalVisible(!isModalVisible);
      return;
      timeoutRef.current = setTimeout(() => {
        // Default action if second click doesn't happen
        setWaitingForSecondClick(false);
        handleSubmit("ask_gpt_concise");
      }, 2000);
    }
  };

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let flashInterval;

    if (actionToRapidFlash) {
      flashInterval = setInterval(() => {
        setFlash((f) => !f); // Toggle the flash state
      }, 150); // 100ms for rapid flash
    } else {
      setFlash(false); // Turn off flash when actionToRapidFlash is null
    }

    return () => clearInterval(flashInterval);
  }, [actionToRapidFlash]);

  useEffect(() => {
    let flashInterval;

    if (waitingForSecondClick) {
      flashInterval = setInterval(() => {
        setFlash((f) => !f); // Toggle the flash state
      }, 400);
    } else {
      setFlash(false); // Ensure flash is off when not waiting for second click
    }

    return () => clearInterval(flashInterval);
  }, [waitingForSecondClick]);

  const Modal = () => (
    <div style={{ display: "flex" }}>
      <div className="input-textbox-container">
        <textarea
          onKeyDown={handleKeyDown}
          className="large-text-input text-input"
          ref={inputRef}
          type="text"
        />
      </div>
      <div>
        <ul style={{ listStyle: "none" }}>
          <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "add_child_note" && flash
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSubmit("add_child_note")}
            >
              add note
            </button>
          </li>
          <li>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "add_child_tag" && flash
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSubmit("add_child_tag")}
            >
              add tag
            </button>
          </li>
          <li>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "translate" && flash ? "selected" : ""
              }`}
              onClick={() => handleSubmit("translate")}
            >
              translate
            </button>
          </li>
          <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                (!actionToRapidFlash ||
                  actionToRapidFlash === "ask_gpt_concise") &&
                flash
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSubmit("ask_gpt_concise")}
            >
              ask gpt
            </button>
          </li>
          {/* <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                (!actionToRapidFlash ||
                  actionToRapidFlash === "ask_gpt_with_assistant") &&
                flash
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSubmit("ask_gpt_with_assistant")}
            >
              ask gpt (a)
            </button>
          </li> */}
          <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "ask_gpt_normal" && flash
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSubmit("ask_gpt_normal")}
            >
              ask gpt -v
            </button>
          </li>
          <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "make_kids" && flash ? "selected" : ""
              }`}
              onClick={() => handleSubmit("make_kids")}
            >
              make kids
            </button>
          </li>
          {/* <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "make_a_few_kids_and_a_few_grandkids" &&
                flash
                  ? "selected"
                  : ""
              }`}
              onClick={() =>
                handleSubmit("make_a_few_kids_and_a_few_grandkids")
              }
            >
              make a few kids and a few grandkids
            </button>
          </li> */}
          <li style={{ marginBottom: "2px" }}>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "make_study_kids" && flash
                  ? "selected"
                  : ""
              }`}
              onClick={() => handleSubmit("make_study_kids")}
            >
              make study kids
            </button>
          </li>
          <li>
            <button
              className={`item-create-modal-button ${
                actionToRapidFlash === "text_bonsai" && flash ? "selected" : ""
              }`}
              onClick={() => handleSubmit("text_bonsai")}
            >
              text bonsai
            </button>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div className="item-create-modal">
      <div className="input-button-container">
        {/* {isModalVisible ? <Modal /> : <button onClick={handleClick}>+</button>} */}
        <button
          style={{
            marginBottom: "6px",
          }}
          onClick={handleClick}
        >
          +
        </button>
        {isModalVisible && <Modal />}
      </div>
    </div>
  );
}

export default ItemCreateModal;
