import React, { useRef, useEffect } from "react";
import { useQuery, useMutation, gql } from "@apollo/client";

export default function TagAdder({
  item,
  submitCallback,
  onTagAdd,
  itemModifierForDisplay,
  childOrParent,
}) {
  const newTagNameRef = useRef();
  const q = gql`
    mutation AddTag(
      $itemId: String!
      $tagName: String!
      $childOrParent: String!
    ) {
      addTag(
        itemId: $itemId
        tagName: $tagName
        childOrParent: $childOrParent
      ) {
        item {
          id
          parentItems {
            id
            name
            text
            isPublic
            importance
            href
            createdAt
          }
          childItems {
            id
            name
            text
            href
            importance
            type
            isPublic
            createdAt
          }
        }
      }
    }
  `;
  const [mutateFunction, { data, loading, error }] = useMutation(q);

  function handleAddTag(e) {
    let newTagName = newTagNameRef.current.value;
    if (newTagName == "") {
      alert("Forreal? Put a damn tag name in that box.");
      return;
    }
    mutateFunction({
      variables: {
        itemId: item.id,
        tagName: newTagName,
        childOrParent: childOrParent,
      },
    });
    newTagNameRef.current.value = "";
  }
  useEffect(() => {
    if (typeof data !== "undefined") {
      console.log(
        "heyhey tag adder here, about to submitCallback",
        data.addTag.item.parentItems
      );
      if (typeof submitCallback === "function") {
        submitCallback(data.addTag.item.parentItems);
      }
      if (typeof onTagAdd === "function") {
        onTagAdd();
      }
    }
  }, [data]);

  return (
    <div className="m-2">
      <input className="small-text-input" ref={newTagNameRef} type="text" />
      <button onClick={handleAddTag}>
        {loading
          ? "hang on.."
          : `add ${childOrParent} tag ${
              itemModifierForDisplay ? "to " + itemModifierForDisplay : ""
            }`}
      </button>
    </div>
  );
}
